.App {
  text-align: center;
}

.App-logo {
  height: 38vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 在屏幕宽度小于或等于768px时应用的样式 */
@media screen and (max-width: 768px) {
  #HeroTitle{
    justify-content: center;
    margin: 0px auto 50px auto;
  }
  .HeroWindow{
    height: 800px;
  }

  .WorkSection {
    flex-direction: column;
  }
  .WorkSectionAll {
    height: 2800px;
  }
  .MobileWork {
    display: block;
  }
  .PCWork {
    display: none;
  }
  #BannerTitle {
    top: 80px;
    font-size: 38px;
    text-align: center;
    position: relative;
  }
  #Banner {
    height: 700px;
  }
 
  .SectionTitle {
    font-size: 38px;
  }
  .FromZeroToHero{
    justify-content: center;
    text-align: left;
 /*    width: 355px; */
  }#HeroSection{
 
    flex-direction: column-reverse;
  }
}
/* 当屏幕宽度大于768px小于1200px时应用的样式 */
@media screen and (min-width: 769px) and (max-width: 1199px) {
  #HeroTitle{
    justify-content: right;
    margin: 0 auto,
  }
  .HeroWindow{
    height: 600px;
  }

  .WorkSection {
    flex-direction: row;
  }
  .WorkSectionAll {
    height: 1800px;
  }
  .MobileWork {
    display: none;
  }
  .PCWork {
    display: block;
  }
  #Banner {
    height: 700px;
    align-items: center;
  }

  .SectionTitle {
    font-size: 50px;
  }
  #BannerTitle {
    text-align: left;
    font-size: 50px;
  }#HeroSection{
    flex-direction: row;
  }
}

/* 当屏幕宽度大于或等于1200px时应用的样式 */
@media screen and (min-width: 1200px) {
  #HeroTitle{
    justify-content: right;
    margin: 0 auto,
  }
  .HeroWindow{
    height: 600px;
  }

  #HeroSection{
    flex-direction: row;
  }
  .WorkSection {
    flex-direction: row;
  }
  .WorkSectionAll {
    height: 1800px;
  }
  .MobileWork {
    display: none;
  }
  .PCWork {
    display: block;
  }
  #Banner {
    height: 700px;
    align-items: center;
  }

  #BannerTitle {
    text-align: left;
    font-size: 50px;
  }
  .SectionTitle {
    font-size: 50px;
  }
}
